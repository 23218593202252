import React from 'react';
import {isMobile} from "../utilities/Functions";
import WhyRated10Creators from '../components/WhyRated10Creators.js';
import WhyRated10Users from '../components/WhyRated10Users.js';
import GetStarted from '../components/GetStarted.js';

const WhyRated10 = () => {
    class Header extends React.Component {
        render() {
          return (
            <header className="App-header">
              <h1>Why you should use Rated10!</h1>
            </header>
          );
        }
      }

  function setPageLayout() {
    return (
      <div className="App">
        <Header />
        <WhyRated10Creators />
        <WhyRated10Users />
        <GetStarted />
      </div>
    );
  }
  return setPageLayout();
};

export default WhyRated10;
